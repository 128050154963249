import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../services/tools.service';
import { ApiService } from '../../services/api.service';
import { ModsService } from 'src/app/services/mods.service';
import * as ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';

// declare var XLSX: any;
declare var X2JS: any;
declare var delete_row: any;

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})

export class FileComponent implements OnInit {

  @Input() public element: any;
  @Input() public scopeId: any;

  fileCount: any = 0;
  fileToUpload;
  fileToUploadPdf;

  constructor(private tools: ToolsService, private api: ApiService, private mods: ModsService) { }

  ngOnInit(): void {
    // console.log('element.value: ', this.element);
  }

  changeImage(target: any) {
    let files = target.files;
    var reader = new FileReader();

    reader.onload = (e) => {
      this.fileToUpload = files.item(0);
      this.fileToUpload.url = e.target.result;
      // console.log('element.value: ', this.element);
      // console.log("🚀 ~ this.fileToUpload:", this.fileToUpload);

      if (!this.fileToUpload) return alert('Imagem não selecionada.');
      if (!this.element.param) return alert('Elemento sem "param".');
      if (!this.element.path) return alert('Elemento sem "path".');

      let formData1 = new FormData();
      formData1.append("file", this.fileToUpload, this.fileToUpload.name);

      this.api.post('https://static.applayos.com:3000/images/' + this.element.param + '/' + this.element.path, formData1, (result) => {
        console.log("🚀 ~ result:", result);
        if (this.element.change) {
          this.tools.runFunc(this.element.change, this.element, this.scopeId, { data: result });
        }
      });
    }
    reader.readAsDataURL(files.item(0)); // Lê o arquivo como URL de dados
  }

  async changePdf(target: any) {
    let files = target.files;
    this.fileToUploadPdf = files.item(0);

    if (!this.fileToUploadPdf) return alert('Arquivo não selecionado.');
    if (!this.element.param) return alert('Elemento sem "param".');
    if (!this.element.path) return alert('Elemento sem "path".');

    const payload = {
      name: this.fileToUploadPdf.name,
      base64: await this.convertInBase64(this.fileToUploadPdf),
      size: this.fileToUploadPdf.size
    }

    this.api.post('https://static.applayos.com:3000/pdfbase64/' + this.element.param + '/' + this.element.path, payload, (result1) => {

      if (this.element.change) {
        this.tools.runFunc(this.element.change, this.element, this.scopeId, { data: result1 });
      }

    });
  }

  convertInBase64(file) {
    return new Promise((resolve, reject) => {
      // const file = files[0];
      if (file) {
        const reader: any = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          const base64String = reader.result.split(',')[1];
          resolve(base64String)
        };
        reader.onerror = function (error) {
          reject(error);
        };
      } else {
        return alert('Arquivo não selecionado')
      }
    });
  }

  async ufile(target) {
    console.log("🚀 ~ ufile")
    var type = this.element.typeFile;
    var files = target.files;
    console.log("🚀 ~ files.length:", files.length)

    this.fileCount = 0;

    this.fileToUpload = files.item(0);

    let fileRaws: any = []
    let filenames: any = [];
    let finalData: any = [];

    for (var i = 0; i < files.length; i++) {
      ((file, name) => {
        console.log("🚀 ~ file:", file)
        var name = file.name;
        console.log("🚀 ~ name:", name)

        var reader = new FileReader();
        reader.onload = async (e) => {
          console.log("🚀 ~ reader.onload:")
          let target = event.target as HTMLInputElement;
          var raw = target['result'];
          fileRaws.push(raw);
          filenames.push(name);

          this.mods.updAlert({ type: 'loading', msg: 'O processo pode levar alguns minutos.', value: true, lock: true })

          // var data = this.readerFileOld({ type: type, raw: raw, filename: target['filename'] });//VERSAO ANTIGA
          // var data = await this.readerFileNew3(file, { type: type, raw: raw, filename: target['filename'] });//MENOS RAPIDA
          // console.log("🚀 ~ data:", data)
          // var data = await this.readerFileNew(file, { type: type, raw: raw, filename: target['filename'] });//MAIS RAPIDA
          var data = await this.readerFileChunk(file, { type: type, raw: raw, filename: target['filename'] });//NOVO TESTE COM CHUNK
          console.log("🚀 ~ data:", data)

          this.mods.updAlert({ type: 'loading', msg: 'O processo pode levar alguns minutos.', value: false, lock: true })

          this.fileCount += 1;

          finalData.push(data);
          if (files.length == this.fileCount) {

            if (files.length == 1) {
              finalData = finalData[0];
              filenames = filenames[0];
            }

            if (['xml'].indexOf(type) >= 0) {
              data = finalData
            }

            if (this.element.change) {
              if (this.element.raw) {
                this.tools.runFunc(this.element.change, this.element, this.scopeId, { data: data, filenames: filenames, fileRaws: fileRaws });
              } else {
                this.tools.runFunc(this.element.change, this.element, this.scopeId, { data: data, filenames: filenames });
              }
            }
          }
        }

        reader['filename'] = name;
        reader['mimeType'] = file.type;
        console.log("🚀 ~ reader:", reader)

        if (['xml', 'txt'].indexOf(type) >= 0) {
          reader.readAsText(file);
        }
        else if (['image', 'png', 'PNG', 'jpg', 'JPG', 'jpeg'].indexOf(type) >= 0) {
          reader.readAsDataURL(file);
        }
        else {
          reader.readAsBinaryString(file);
        }
      })(files[i]);
    }
  }

  readerFileOld(file) {
    var raw = file.raw;
    var type = file.type;
    var rule = file.rule;
    if (type == 'xlsx' || type == 'xls') {
      var workbook = XLSX.read(raw, { type: 'binary', cellFormula: false });
      var sheet_list = workbook.SheetNames;
      var data = {};
      for (var x1 in sheet_list) {
        if (rule && rule.split('shift').length > 1) {
          for (var x2 = 0; x2 < parseInt(rule.split('shift')[1] || '1'); x2++) {
            workbook.Sheets[sheet_list[x1]] = delete_row(workbook.Sheets[sheet_list[x1]], 0);
          }
        }
        // var lista = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet_list[x1]], { raw: true });
        // console.log("🚀 ~ lista:", lista)
        // data[sheet_list[x1]] = lista;
      }
      return data;
    }
    if (type == 'xml') {
      var x2js = new X2JS();
      var data: {} = x2js.xml2js(raw);
      return data
    }
    return raw
  }

  async readerFileNew(file, params) {//FASTEST FUNC
    return new Promise(resolve => {
      console.log("🚀 ~ readerFile2, params: ", params)
      let raw = params.raw;
      let type = params.type;
      let rule = params.rule;
      console.log("🚀 ~ file:", file)

      var workbook2 = XLSX.read(raw, { type: 'binary', cellFormula: false });
      var sheet_list = workbook2.SheetNames;
      console.log("🚀 ~ sheet_list:", sheet_list)

      if (type == 'xlsx' || type == 'xls') {

        for (var x1 in sheet_list) {
          if (rule && rule.split('shift').length > 1) {
            for (var x2 = 0; x2 < parseInt(rule.split('shift')[1] || '1'); x2++) {
              workbook2.Sheets[sheet_list[x1]] = delete_row(workbook2.Sheets[sheet_list[x1]], 0);
            }
          }

          // var lista = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet_list[x1]], { raw: true });
          // console.log("🚀 ~ lista:", lista)
          // data[sheet_list[x1]] = lista;

          // const file: any = event.target.files[0];
          const workbook = new ExcelJS.Workbook();
          const reader = new FileReader();

          reader.onload = async (e: any) => {
            console.log("🚀 ~ e:", e)
            const buffer = e.target.result;
            await workbook.xlsx.load(buffer);

            const allSheetsData = [];

            workbook.eachSheet((worksheet, sheetId) => {
              const jsonData = [];
              let headers = [];

              worksheet.eachRow((row, rowNumber) => {
                if (rowNumber === 1) {
                  // Primeira linha, assumimos que são os headers
                  row.eachCell((cell, colNumber) => {
                    headers[colNumber] = cell.value;
                  });
                } else {
                  // Linhas subsequentes, criamos os objetos com base nos headers
                  const rowData = {};
                  row.eachCell((cell, colNumber) => {
                    rowData[headers[colNumber]] = cell.value;
                  });
                  jsonData.push(rowData);
                }
              });

              // allSheetsData.push({
              //   sheetName: worksheet.name,
              //   data: jsonData
              // });
              allSheetsData[worksheet.name] = jsonData
            });

            // console.log('allSheetsData: ', allSheetsData);
            resolve(allSheetsData)
            // Aqui você pode fazer o que quiser com os dados processados
          };

          reader.readAsArrayBuffer(file);
        }
      }
      if (type == 'xml') {
        var x2js = new X2JS();
        var data: {} = x2js.xml2js(raw);
        return data
      }
      return raw
    })
  }

  async readerFileNew2(file, params) {
    return new Promise(resolve => {
      console.log("🚀 ~ readerFile, params: ", params)
      let raw = params.raw;
      let type = params.type;
      let rule = params.rule;
      console.log("🚀 ~ file:", file)

      var workbook2 = XLSX.read(raw, { type: 'binary', cellFormula: false });
      var sheet_list = workbook2.SheetNames;
      console.log("🚀 ~ sheet_list:", sheet_list)

      if (type == 'xlsx' || type == 'xls') {

        for (var x1 in sheet_list) {
          if (rule && rule.split('shift').length > 1) {
            for (var x2 = 0; x2 < parseInt(rule.split('shift')[1] || '1'); x2++) {
              workbook2.Sheets[sheet_list[x1]] = delete_row(workbook2.Sheets[sheet_list[x1]], 0);
            }
          }

          // var lista = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet_list[x1]], { raw: true });
          // console.log("🚀 ~ lista:", lista)
          // data[sheet_list[x1]] = lista;

          // const file: any = event.target.files[0];
          const workbook: any = new ExcelJS.Workbook();
          const reader = new FileReader();

          console.time('reader.onload')
          reader.onload = async (e: any) => {
            console.timeEnd('reader.onload')
            const buffer = e.target.result;
            const arrayBuffer = new Uint8Array(buffer);
            const stream: any = new ReadableStream({
              start(controller) {
                controller.enqueue(arrayBuffer);
                controller.close();
              }
            });

            console.time('await workbook')
            await workbook.xlsx.read(stream);
            console.log("🚀 ~  await workbook")
            console.timeEnd('await workbook')

            const allSheetsData = [];

            console.time('workbook.eachSheet')
            workbook.eachSheet((worksheet, sheetId) => {
              console.log("🚀 ~ workbook.eachSheet")
              const jsonData = [];
              let headers = [];

              worksheet.eachRow((row, rowNumber) => {
                console.log("🚀 ~ worksheet.eachRow")
                if (rowNumber === 1) {
                  // Primeira linha, assumimos que são os headers
                  row.eachCell((cell, colNumber) => {
                    headers[colNumber] = cell.value;
                  });
                } else {
                  // Linhas subsequentes, criamos os objetos com base nos headers
                  const rowData = {};
                  row.eachCell((cell, colNumber) => {
                    rowData[headers[colNumber]] = cell.value;
                  });
                  jsonData.push(rowData);
                }
              });

              // allSheetsData.push({
              //   sheetName: worksheet.name,
              //   data: jsonData
              // });
              allSheetsData[worksheet.name] = jsonData
            });
            console.timeEnd('workbook.eachSheet')

            console.log(allSheetsData);
            resolve(allSheetsData)
            // Aqui você pode fazer o que quiser com os dados processados
          };

          reader.readAsArrayBuffer(file);
        }
      }
      if (type == 'xml') {
        var x2js = new X2JS();
        var data: {} = x2js.xml2js(raw);
        return data
      }
      return raw
    })
  }

  async readerFileNew3(file, params) {
    return new Promise(resolve => {
      console.log("🚀 ~ readerFile, params: ", params)
      let raw = params.raw;
      let type = params.type;
      let rule = params.rule;
      console.log("🚀 ~ file:", file)

      var workbook2 = XLSX.read(raw, { type: 'binary', cellFormula: false });
      var sheet_list = workbook2.SheetNames;
      console.log("🚀 ~ sheet_list:", sheet_list)

      if (type == 'xlsx' || type == 'xls') {

        for (var x1 in sheet_list) {
          if (rule && rule.split('shift').length > 1) {
            for (var x2 = 0; x2 < parseInt(rule.split('shift')[1] || '1'); x2++) {
              workbook2.Sheets[sheet_list[x1]] = delete_row(workbook2.Sheets[sheet_list[x1]], 0);
            }
          }

          // var lista = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet_list[x1]], { raw: true });
          // console.log("🚀 ~ lista:", lista)
          // data[sheet_list[x1]] = lista;

          // const file: any = event.target.files[0];
          const reader = new FileReader();

          reader.onload = (e: any) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const allSheetsData = [];

            workbook.SheetNames.forEach(sheetName => {
              const worksheet = workbook.Sheets[sheetName];
              const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
              // allSheetsData.push({
              //   sheetName: sheetName,
              //   data: jsonData
              // });
              allSheetsData[sheetName] = jsonData
            });

            // console.log(allSheetsData);
            resolve(allSheetsData)
            // Aqui você pode fazer o que quiser com os dados processados
          };

          reader.readAsArrayBuffer(file);
        }
      }
      if (type == 'xml') {
        var x2js = new X2JS();
        var data: {} = x2js.xml2js(raw);
        return data
      }
      return raw
    })
  }

  async readerFileChunk(file, params) {
    return new Promise(resolve => {
      console.log("🚀 ~ readerFile, params: ", params)
      let raw = params.raw;
      let type = params.type;
      let rule = params.rule;
      console.log("🚀 ~ file:", file)

      var workbook2 = XLSX.read(raw, { type: 'binary', cellFormula: false });
      var sheet_list = workbook2.SheetNames;
      console.log("🚀 ~ sheet_list:", sheet_list)

      if (type == 'xlsx' || type == 'xls') {

        for (var x1 in sheet_list) {
          console.log("🚀 ~ x1:", x1)
          if (rule && rule.split('shift').length > 1) {
            for (var x2 = 0; x2 < parseInt(rule.split('shift')[1] || '1'); x2++) {
              workbook2.Sheets[sheet_list[x1]] = delete_row(workbook2.Sheets[sheet_list[x1]], 0);
            }
          }

          // var lista = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet_list[x1]], { raw: true });
          // console.log("🚀 ~ lista:", lista)
          // data[sheet_list[x1]] = lista;

          // const file: any = event.target.files[0];
          // const chunkSize = (1024 * 1024) * 28; // 1MB
          const chunkSize = file.size
          let offset = 0;
          let dataXlsx: any = []

          const readChunk = () => {
            const reader = new FileReader();
            const blob = file.slice(offset, offset + chunkSize);

            reader.onload = function (e: any) {
              const data = new Uint8Array(e.target.result);
              const workbook = XLSX.read(data, { type: 'array' });

              // Processar o workbook aqui
              const firstSheetName = workbook.SheetNames[x1];
              const worksheet = workbook.Sheets[firstSheetName];
              if (!dataXlsx[firstSheetName]) {
                dataXlsx[firstSheetName] = []
              }
              const json = XLSX.utils.sheet_to_json(worksheet);
              console.log('json: ', json);
              dataXlsx[firstSheetName] = dataXlsx[firstSheetName].concat(json).flat()
              offset += chunkSize;
              if (offset < file.size) {
                readChunk();
              } else {
                resolve(dataXlsx)
              }
            };

            reader.readAsArrayBuffer(blob);
            // reader.readAsBinaryString(blob);
          };

          readChunk();
        }
      }
      if (type == 'xml') {
        var x2js = new X2JS();
        var data: {} = x2js.xml2js(raw);
        return data
      }
      return raw
    })
  }
}
