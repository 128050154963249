<div class="content-ia">

  <!-- {{comp | json}} -->

  <div class="content-logo-ia">
    <img class="image" src="https://static.applayos.com/files/crm/8060096f16f2ac07f4ca0ca204d6dc1f-Logo_Ia.png" alt="">

    <div *ngIf="false" class="content-input">
      <input class="input-chat" type="text" placeholder="pergunte-me o que quiser">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
          <path
            d="M16.658 5.39111C16.8576 5.46012 16.9211 5.48854 17.0405 5.66957C17.0695 5.86496 17.0664 6.05959 17.0662 6.25704C17.0943 7.62962 17.3964 8.86778 18.0176 10.0705C18.0435 10.1219 18.0695 10.1733 18.0962 10.2263C18.284 10.5791 18.5244 10.866 18.789 11.1571C18.8315 11.2042 18.8739 11.2513 18.9176 11.2998C18.96 11.3423 19.0024 11.3849 19.0462 11.4288C19.0904 11.4751 19.0904 11.4751 19.1356 11.5224C20.2195 12.6072 21.6985 13.0882 23.1539 13.2177C23.2109 13.2231 23.268 13.2285 23.3268 13.2341C23.3778 13.2383 23.4287 13.2426 23.4812 13.2469C23.6924 13.2903 23.8972 13.336 23.9831 13.5478C24.0546 13.724 24.0337 13.8809 23.9156 14.03C23.625 14.175 23.5717 14.1149 23.3528 14.175C21.6618 14.6392 20.3791 14.8111 19.2004 15.8841C19.1621 15.9167 19.1238 15.9493 19.0843 15.983C17.7805 17.13 17.2528 19.0007 17.0919 20.7196C17.0801 20.9525 17.0719 21.1857 17.0735 21.419C17.0698 21.6374 17.0487 21.7407 16.9216 21.9164C16.7834 22.0236 16.7834 22.0236 16.597 22.027C16.3998 21.9614 16.3639 21.9407 16.2691 21.752C16.2461 21.5916 16.2455 21.4318 16.2434 21.2698C16.183 19.403 15.728 17.5784 14.4692 16.2101C14.4378 16.1735 14.4063 16.1369 14.374 16.0993C13.2269 14.8048 11.4671 14.273 9.84078 14.176C9.66533 14.1617 9.57873 14.1429 9.41651 14.0605C9.30694 13.8993 9.29595 13.7965 9.29236 13.6021C9.34534 13.4335 9.44692 13.3746 9.58364 13.2761C9.72788 13.2416 9.72788 13.2416 9.88577 13.2307C9.94438 13.2257 10.003 13.2208 10.0634 13.2157C10.1568 13.2086 10.1568 13.2086 10.2522 13.2014C11.5072 13.0808 12.7347 12.6772 13.7492 11.8635C13.797 11.8256 13.8448 11.7877 13.894 11.7486C15.2379 10.6369 15.9077 8.99804 16.1489 7.24842C16.1861 6.89245 16.1903 6.53273 16.1873 6.17481C16.1908 5.72174 16.1908 5.72174 16.3366 5.52228C16.4748 5.3979 16.4748 5.3979 16.658 5.39111Z"
            fill="#4B84EC" />
          <path
            d="M4.54383 14.1998C4.69191 14.3487 4.74516 14.4384 4.77364 14.6538C4.77444 14.7569 4.77444 14.7569 4.77525 14.862C4.81073 15.9906 5.15714 16.9616 5.93998 17.7522C6.54346 18.3091 7.23366 18.5192 8.01507 18.5946C8.28646 18.6237 8.46475 18.6491 8.65796 18.8724C8.71261 19.032 8.71261 19.032 8.70939 19.1984C8.61835 19.3477 8.55188 19.4446 8.40083 19.5244C8.30123 19.5349 8.20159 19.5451 8.10184 19.5539C7.09886 19.646 6.20944 20.0272 5.52094 20.8284C4.97727 21.6055 4.80217 22.418 4.74321 23.3615C4.73953 23.4101 4.73585 23.4587 4.73206 23.5087C4.72921 23.5523 4.72637 23.5958 4.72343 23.6407C4.69226 23.7904 4.64415 23.8695 4.54383 23.9797C4.36705 24.0068 4.36705 24.0068 4.18384 23.9797C4.03434 23.8591 3.98637 23.7884 3.92671 23.5993C3.92096 23.4897 3.91545 23.3801 3.91179 23.2704C3.87536 22.1908 3.57103 21.216 2.81963 20.4553C2.15994 19.8541 1.31089 19.5608 0.448977 19.5006C0.289485 19.4859 0.228823 19.4732 0.0889905 19.3818C-0.0119051 19.1977 -0.0135021 19.0813 0.0182787 18.8724C0.215935 18.6525 0.396444 18.6238 0.670755 18.6075C1.56773 18.5218 2.40695 18.1679 3.04603 17.4835C3.66749 16.6925 3.88259 15.7233 3.93402 14.7193C3.9557 14.3891 3.9557 14.3891 4.07295 14.2518C4.24163 14.1727 4.36196 14.1724 4.54383 14.1998Z"
            fill="#4B84EC" />
          <path
            d="M7.93808 0.01903C8.07515 0.131402 8.13543 0.201241 8.17804 0.380362C8.18211 0.434256 8.18619 0.48815 8.19039 0.543677C8.19845 0.633816 8.19845 0.633816 8.20666 0.725776C8.21137 0.78839 8.21608 0.851004 8.22093 0.915515C8.30129 1.95587 8.62579 2.89248 9.37803 3.60497C10.0528 4.13492 10.7813 4.38062 11.6119 4.44033C11.7774 4.45723 11.8552 4.47907 12.0072 4.56258C12.1036 4.69162 12.1036 4.69162 12.1358 4.87839C12.0948 5.12505 12.0318 5.19008 11.8465 5.34361C11.6962 5.37416 11.5668 5.39068 11.4158 5.39794C10.4473 5.47911 9.62048 5.89209 8.96641 6.65989C8.47993 7.2882 8.21109 8.07902 8.18617 8.88943C8.18495 8.9289 8.18372 8.96837 8.18246 9.00903C8.18023 9.09023 8.17863 9.17145 8.17763 9.25268C8.17065 9.44507 8.15856 9.56057 8.04836 9.71694C7.89822 9.82847 7.80941 9.82658 7.62952 9.79886C7.46825 9.70216 7.43199 9.66178 7.37239 9.47287C7.36759 9.3734 7.36301 9.2739 7.36012 9.17435C7.32644 8.11947 7.03923 7.10829 6.32477 6.33963C5.68424 5.73933 4.85286 5.42363 4.00394 5.37417C3.81882 5.36048 3.72523 5.34584 3.55717 5.25532C3.4288 5.07761 3.4014 4.96945 3.41253 4.74595C3.6322 4.50199 3.80777 4.43246 4.12607 4.40977C4.19968 4.40215 4.27327 4.39437 4.34685 4.38642C4.41188 4.37956 4.47691 4.37269 4.54392 4.36562C5.24783 4.24516 5.91721 3.8945 6.39528 3.33331C6.41934 3.30683 6.44341 3.28036 6.4682 3.25308C7.02802 2.61145 7.29773 1.73906 7.3312 0.878798C7.33282 0.83987 7.33443 0.800942 7.33609 0.760835C7.3388 0.6844 7.34058 0.607919 7.34129 0.531434C7.34702 0.350268 7.3622 0.250764 7.466 0.103924C7.63028 -0.0204979 7.74111 -0.0106988 7.93808 0.01903Z"
            fill="#4B84EC" />
        </svg>
      </div>
    </div>
  </div>

  <div class="divisor"></div>

  <h1 class="title">Oportunidades de ativações</h1>

  <div class="content-opportunities">

    <div class="content-calendar" *ngIf="calendar">
      <span style="margin-right: '32px'" (click)="click(calendar, calendar.buttonPrevious)">
        <i class="btn fa-solid fa-caret-left"></i>
      </span>
      <div class="content-calendar-titles">
        <span class="data">{{calendar.date}}</span>
        <span class="dia">{{calendar.day}}</span>
      </div>
      <span style="margin-left: '32px'" (click)="click(calendar, calendar.buttonNext)">
        <i class="btn fa-solid fa-caret-right"></i>
      </span>
    </div>

    <div class="content-cards" *ngIf="cards">
      <div *ngFor="let card of cards.cards" class="card" (click)="click(card, card.click)">
        <div class="header-card">{{card.header}}</div>
        <div class="content-card">{{card.content}}</div>
        <div class="footer-card">{{card.footer}}</div>
      </div>
    </div>

    <div class="content-carousel-items" *ngIf="carousel">
      <div class="title-items">Oportunidade de identificação</div>
      <div class="content-carousel">
        <span style="margin-left: '76px'" class="content-btn" (click)="click(carousel, carousel.buttonPrevious)">
          <i class="btn fa-solid fa-caret-left"></i>
        </span>
        <div class="content-items">
          <div class="content-item" *ngFor="let item of carousel.items; index as i">
            <div class="content-image"><img class="image"
                [src]="item.image?item.image:'https://static.applayos.com:3000/files/produtos/default.jpg'" alt="">
            </div>
            <div class="description">{{item.name}}</div>
            <div class="description">{{item.sku}}</div>
          </div>
          <!-- 
          <div class="content-item">
            <div class="image"><img src="" alt=""></div>
            <div class="description">2º- Azeite Extravirgem Argentino Nucete Vidro 500ml </div>
          </div>

          <div class="content-item">
            <div class="image"><img src="" alt=""></div>
            <div class="description">3º- Arroz Camil Agulhinha 2kg </div>
          </div>

          <div class="content-item">
            <div class="image"><img src="" alt=""></div>
            <div class="description">4º- Atum Gomes Da Costa Sólido Natural 120g </div>
          </div>

          <div class="content-item">
            <div class="image"><img src="" alt=""></div>
            <div class="description">5º- Bombom Nestlé Especialidades - 251g </div>
          </div> -->
        </div>
        <span style="margin-right: '76px'" class="content-btn" (click)="click(carousel, carousel.buttonNext)">
          <i class="btn fa-solid fa-caret-right"></i>
        </span>
      </div>
    </div>

  </div>


</div>