import { Injectable } from '@angular/core';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class LangService {

  lang: any = {
    "title": "pt-BR",
    "lang": "pt",
    "msg": {
      "escolha": "Escolha uma Linguagem",
      "caminho": "Voce está aqui",
      "sair": "Sair",
      "notificacoes": "Notificações",
      "semNotificacoes": "Sem Notificações",
      "marcarLidas": "Marcar todas como Lidas",
      "minhaConta": "Minha Conta",
      "buscarPor": "Buscar Por",
      "removerItem": "Tem certeza que deseja remover ?",
      "login": {
        "msg_conectado": "Conectado",
        "title_sistema": "Sistema",
        "title_login": "Login",
        "title_senha": "Senha",
        "title_lembrar_senha": "Lembrar senha",
        "btn_entrar": "ENTRAR",
        "btn_esqueci_senha": "Esqueci minha senha"
      }
    },
    "status": true
  };

  texts: any = {};
  languages: any = [];

  constructor(private api: ApiService) { }

  getLang() {
    return this.lang;
  }

  setLang(lang) {
    this.getLanguages(langs => this.lang = langs.find(e => e.lang == (lang || 'pt')));
  }

  getLanguages(callback) {
    if (this.languages.length > 0) return callback(this.languages);

    let dataLanguages = { base: 'sysLangs', filter: { ativo: true } }

    this.api.portal('getdata', dataLanguages, (result: any) => {
      if (result.data.length == 0) {
        result.data = [
          {
            ativo: false, nome: 'pt-BR', id: 'pt', msg: {
              escolha: 'Escolha um Idioma',
              caminho: 'Você está aqui',
              sair: 'Sair',
              notificacoes: 'Notificações',
              semNotificacoes: 'Sem Notificações',
              marcarLidas: 'Marcar como Lidas',
              minhaConta: 'Minha Conta',
              buscarPor: 'Buscar Por',
              removerItem: 'Tem certeza que deseja remover?'
            }
          }
        ]
      }
      this.languages = result.data.map(e => ({ title: e.nome, lang: e.id, msg: e.msg, status: e.ativo }));
      callback(this.languages);
    });
  }

  setTexts(texts) {
    this.texts = texts;
  }

  getText(variavel) {
    if (!this.texts[variavel]) {
      return `[err:${variavel}]`;
    }

    if (!this.texts[variavel][this.lang.lang] || this.texts[variavel][this.lang.lang] == "") {
      return this.texts[variavel]['pt'];
    }
    
    return this.texts[variavel][this.lang.lang];
  }

}
