import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { ApiService } from './api.service';
import { LangService } from './lang.service';

declare var window: any;


@Injectable({
  providedIn: 'root'
})

export class ModsService {

  private emit = new Subject<any>();
  listenMod = this.emit.asObservable();

  private emitLayout = new Subject<any>();
  updateLayout = this.emitLayout.asObservable();

  private emitAlert = new Subject<any>();
  listenAlert = this.emitAlert.asObservable();

  private emitComponents = new Subject<any>();
  listenComponents = this.emitComponents.asObservable();

  mods: any = [];
  modActive: any = '';
  systemActive: any = '';
  hashActive: any = '';

  constructor(
    private api: ApiService,
    private lang: LangService,
  ) { }

  getMod(id, systemName) {
    let system = id.split('.')[0];
    let modId = id.split('.')[1];

    this.api.portal('mod', { system: system, mod: modId, env: this.api.getEnv() }, async (result) => {
      if (!result.status) {
        alert(result.msg);
        console.log('error', result);
        return
      }
      // console.log('modulo bruto', result.data.mod);

      window.extractError = (err)=>{
        var line = parseInt(err.split('<anonymous>:')[1].split(':')[0]);
        var col = err.split('<anonymous>:')[1].split(':')[1].split(')')[0];
        var funcname = err.split('Object.eval [as ')[1].split(']')[0];
        var obj = {
          errLocal: `modsv3/${system}/${modId}.js => [${funcname}]${line}:${col} - Error:`,
          errType: err.split('\n')[0],
          err
        }
        console.error(obj.errLocal + obj.errType, err);
        // alert(obj.errLocal + obj.errType);
      }
      
      //VERSÃO SEM TRATAMENTO DE ERRO
      var mod = JSON.parse(result.data.mod, function (key, value) {
        if (typeof value === "string" &&
          value.startsWith("/Function(") &&
          value.endsWith(")/")) {
          value = value.substring(10, value.length - 2);
          return (0, eval)("(" + value + ")");
        }
        return value;
      });

      
      // var mod = JSON.parse(result.data.mod, function (key, value) {
      //   if (typeof value === "string" && value.startsWith("/Function(") && value.endsWith(")/")) {
      //     value = value.substring(10, value.length - 2);
      //     var startFunc = value.split('{')[0] + '{';
      //     var conteudo = value.split(startFunc)[1].split('}').slice(0, value.split('}').length - 1).join('}');
      //     var funcTry = `${startFunc}try{${conteudo}}catch(e){extractError(e.stack)}}`;
      //     return (0, eval)("(" + funcTry + ")");
      //   }
      //   return value;
      // });
      

      if (result.data.status === false) {
        // console.log(result.data)
        // console.log(mod.message)
        // console.log(mod.stack)
        alert('Erro de sintax verifique o log');
        return
      }

      var md5Hash = Md5.hashStr(result.data.mod)
      if (md5Hash != result.data.hash) {
        alert('Módulo corrompido');
        // console.log('Módulo corrompido', md5Hash, result.data.hash)
        return
      }

      let valMod = this.validatorMod(mod);
      if (!valMod.status) {
        alert(valMod.msg);
        return
      }

      if (!systemName || systemName == '') { systemName = this.systemActive +''}
      
      if (this.systemActive + '#' + this.modActive == systemName + '#' + id && this.hashActive != result.data.hash) {
        alert('Houveram alterações no módulo!');
      }

      this.modActive = id;
      this.systemActive = systemName;
      this.hashActive = result.data.hash;

      let modObj = {
        id: id,
        system: systemName,
        mod: mod,
        hash: result.data.hash
      };
      
      this.lang.setTexts(result.data.lang);

      this.mods.push(modObj);
      this.updMod(modObj);
    })
  }

  validatorMod(mod) {
    if (!mod.title && !mod.titleBind) { return { status: false, msg: '[title] Campo obrigatório não definido!' }; }
    if (!mod.start) { return { status: false, msg: '[start] Campo obrigatório não definido!' }; }
    if (!mod.layouts) { return { status: false, msg: '[layouts] Campo obrigatório não definido!' }; }
    if (!Array.isArray(mod.layouts) || mod.layouts.length == 0) { return { status: false, msg: '[layouts] deve ser uma Array e deve conter ao menos um layout!' }; }
    
    return { status: true };
  }

  public updMod(data: any) {
    this.emit.next(data);
  }

  public updComponents(data: any) {
    this.emitComponents.next(data);
  }

  updAlert(data: any, callback?) {
    this.emitAlert.next(data);
  }

  updAlertAsync(data: any) {
    new Promise(resolve => this.updAlert(data, result => resolve(result)))
  }

  public layout(data: any) {
    var base = { modId: this.modActive, system: this.systemActive };
    this.emitLayout.next(Object.assign(base, data));
  }

  public getActive() {
    return this.modActive;
  }
}
