import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../services/tools.service';
import * as showdown from 'showdown';

declare var document: any

@Component({
  selector: 'app-ia',
  templateUrl: './ia.component.html',
  styleUrls: ['./ia.component.css']
})

export class IaComponent implements OnInit {

  converter: any

  @Input() public comp: any;
  @Input() public element: any;
  @Input() public scopeId: any;

  //COMPONENTS CHAT
  home: any;
  ia: any;
  chat: any;

  step: any = 'Sugestões'

  //HIST CHATS
  histChats: any

  //SUGESIONS
  sugestions: any = [
    "Top 5 produtos com mais venda",
    "Top 5 clienets com o melhor tkm",
    "Top 10 pessoas que compram coca-cola",
    "Top 10 produtos de quem compra produtos Nestle",
    "Produtos mais frequentes em compras distintas com a quantidade total vendida",
  ]

  //USER
  user: any = {}

  //CHAT CONTROL VARIABLES OF CHAT
  localChat: boolean = false;
  loading: boolean = false;
  listIsTrue: boolean = false;
  isLocalOrIsChat: boolean = true;
  response: string = 'Olá, eu sou a inteligência artificial Applay, como posso te ajudar hoje?'
  // request: string = 'Olá, meu nome é Luis'
  request: string = ''
  requested: string = ''
  hist: Array<String> = []
  histMapWhoIs: any = []
  listResponse: any = {}
  markDownTest: any = `  - IA
  + Colocar Voz
  + inverter icones
  + Gerar grafico
  + Printar resposta no Chat (Markdown)
    - showdownjs para markdown no chat
  + Forum para IA`

  //WRITER
  textToWrite: string = ''
  indexWriter: number = 0
  minSpeed: number = 2; // Velocidade mínima de digitação em milissegundos
  maxSpeed: number = 8; // Velocidade máxima de digitação em milissegundos

  //COMPONENTES LOCALCHAT
  dotHtml: any
  chatHtml: any
  btnCloseHtml: any
  btnOpenChatHtml: any
  contentIaHtml: any
  requestHtml: any
  responseHtml: any
  contentInputHtml: any
  inputHtml: any
  btnSendHtml: any

  timeForSession: any = 0

  constructor(
    private tools: ToolsService,
    // private Showdown: showdown
  ) {
    this.hist.push(this.response)
    this.histMapWhoIs[0] = { chatIA: true }
    this.user = this.tools.getUserInfo()
    console.log("🚀 ~ this.user:", this.user)
  }

  ngOnInit(): void {
    // console.log('this.comp: ', this.comp);
    this.home = this.comp.elements.find(e => e.elementType == 'home')
    this.ia = this.comp.elements.find(e => e.elementType == 'ia')
    this.chat = this.comp.elements.find(e => e.elementType == 'chat')
    this.loadHistChats()

    let html = document.querySelector('html')
    if (html) {
      html.classList.remove('ajuste-zoom')
    }
  }

  ngOnDestroy() {
    console.log("🚀 ~ ngOnDestroy, ia:")
    let html = document.querySelector('html')
    if (html) {
      // html.classList.remove('remove-zoom')
      html.classList.add('ajuste-zoom')
    }
  }

  async click(element: any, func: string, toLocalOrChat?: boolean, idLista?: number) {
    // console.log("🚀 ~ func:", func)
    let document = element.view.document

    if (func == 'local-chat') {
      this.localChat = true
      //INSTANCIA COMPONENTS LOCALCHAT
      //HURDUR
      setTimeout(() => { this.createLocalChat(document) }, 200)
      // await this.createLocalChat(document)
      this.timeForSession = new Date().getTime()
    }
    else if (func == 'close-local-chat') {//FECHA LOCAL CHAT

      //ADD EFFECT COMPONENTS LOCALCHAT
      await this.addCloseEffectContentLocalChat(document)

      // this.hist = []
      // this.histMapWhoIs = []
      // this.requested = ''
      // this.request = ''
      // this.response = 'Olá, eu sou a inteligência artificial Applay, como posso te ajudar hoje?'

      setTimeout(async () => {
        //REMOVE USING *ngIf
        this.localChat = false

        //REMOVE EFFECT COMPONENTS LOCALCHAT
        await this.removeCloseEffectContentLocalChat(document)
      }, 500)
    }
    else if (func == 'send') {//ENVIA PERGUNTA
      if (!this.request) return
      let request = this.request
      this.request = ''

      //START LOADING
      this.loadingUpd(true)
      this.hist.push(request)
      this.histMapWhoIs[this.histMapWhoIs.length] = { chatIA: false }
      this.requested = request

      //API
      let resultRequest: any = await this.tools.sendRequest(request, this.timeForSession)
      console.log("🚀 ~ resultRequest:", resultRequest)
      if (!resultRequest.status) {
        return
      }
      // this.loading = false
      this.loadingUpd(false)

      if (resultRequest.list.length > 0) {
        //CASO EXISTA LISTA
        // console.log("🚀 ~ CASO EXISTA LISTA")

        if (resultRequest.msg) {
          // this.hist.push(resultRequest.msg)
          this.hist.push('')
          this.histMapWhoIs[this.histMapWhoIs.length] = { chatIA: true, list: true, resultRequest }
          // this.textToWrite = resultRequest.msg
          this.textToWrite = resultRequest.pretty.markdown
          this.startTypeWriter()
        }

        //EXIBIR BOTÃO MOSTRAR LISTA PARA ATUALIZAR COMPONENTES DA TELA
        this.listIsTrue = true
        this.listResponse = resultRequest
      }
      else {
        //CASO NÃO EXISTA LISTA
        // console.log("🚀 ~ CASO NÃO EXISTA LISTA")
        if (resultRequest.msg) {
          this.hist.push(resultRequest.msg)
          this.histMapWhoIs[this.histMapWhoIs.length] = { chatIA: true }
          this.textToWrite = resultRequest.msg
          this.startTypeWriter()
        }
      }

    } else if (func == 'backRequest') {//
      this.listIsTrue = false
      // console.log("🚀 ~ this.response:", this.response)
      this.hist.push(this.response)
      this.histMapWhoIs[this.histMapWhoIs.length] = { chatIA: true }
      this.response = 'Me faça uma pergunta.'

    } else {//ACIONA FUNC DO MÓDULO
      let listToSend = []
      let msgToSend = ''
      let grafToSend = {}
      if (idLista) {
        listToSend = this.histMapWhoIs[idLista]?.resultRequest?.list
        msgToSend = this.histMapWhoIs[idLista]?.resultRequest?.msg || ''
        grafToSend = this.histMapWhoIs[idLista]?.resultRequest.pretty.grafico
      } else {
        listToSend = this.listResponse.list
        msgToSend = this.listResponse?.msg || ''
        grafToSend = this.histMapWhoIs[idLista]?.resultRequest?.pretty?.grafico || false
      }

      this.tools.cmdFunc('layer', func, { list: listToSend, title: msgToSend, graphic: grafToSend });

      this.isLocalOrIsChat = toLocalOrChat
    }
  }

  async loadingUpd(isLoading: boolean) {
    this.loading = isLoading

    if (isLoading) {
      if (this.responseHtml) this.responseHtml.classList.add('loading-hide-response')
      if (this.contentInputHtml) this.contentInputHtml.classList.add('loading-hide-content-input')
      setTimeout(() => {
        if (this.responseHtml) this.responseHtml.classList.add('displayNone')
        if (this.contentInputHtml) this.contentInputHtml.classList.add('displayNone')
      }, 500)
      return
    }

    if (this.responseHtml) this.responseHtml.classList.remove('loading-hide-response')
    if (this.contentInputHtml) this.contentInputHtml.classList.remove('loading-hide-content-input')
    if (this.responseHtml) this.responseHtml.classList.remove('displayNone')
    if (this.contentInputHtml) this.contentInputHtml.classList.remove('displayNone')
  }

  async createLocalChat(document: any) {
    //FUNCTION CREATE HTML COMPONENTS
    //DOT
    this.dotHtml = document.getElementById('dot')
    //CHAT
    this.chatHtml = document.getElementById('content-local-chat')
    //BUTTONS
    this.btnCloseHtml = document.getElementById('btn-close-local-chat')
    this.btnOpenChatHtml = document.getElementById('btn-open-chat')
    //CONTENT IA
    this.contentIaHtml = document.getElementById('content-ia')
    this.requestHtml = document.getElementById('request')
    this.responseHtml = document.getElementById('response')
    this.contentInputHtml = document.getElementById('content-input')
    this.inputHtml = document.getElementById('input')
    this.btnSendHtml = document.getElementById('btn-send')

    return true
  }

  async addCloseEffectContentLocalChat(document: any) {
    //DOT
    this.dotHtml.classList.add('close-dot')
    //CHAT
    this.chatHtml.classList.add('close-content-local-chat')
    //BUTTONS
    this.btnCloseHtml.classList.add('btn-close')
    this.btnOpenChatHtml.classList.add('btn-close')
    //CONTENT IA
    this.requestHtml.classList.add('close-content-chat')
    this.responseHtml.classList.add('close-response')
    this.contentInputHtml.classList.add('close-content-chat')
    this.inputHtml.classList.add('close-input')
    this.btnSendHtml.classList.add('close-btn-send')
    this.contentIaHtml.classList.add('close-content-ia')

    return true
  }

  async removeCloseEffectContentLocalChat(document: any) {
    //REMOVE CLOSE EFFECT
    //DOT
    this.dotHtml.classList.remove('close-dot')
    //CHAT
    this.chatHtml.classList.remove('close-content-local-chat')
    //BUTTONS
    this.btnCloseHtml.classList.remove('btn-close')
    this.btnOpenChatHtml.classList.remove('btn-close')
    //CONTENT IA
    this.requestHtml.classList.remove('close-content-chat')
    this.responseHtml.classList.remove('close-response')
    this.contentInputHtml.classList.remove('close-content-chat')
    this.inputHtml.classList.remove('close-input')
    this.btnSendHtml.classList.remove('close-btn-send')
    this.contentIaHtml.classList.remove('close-content-ia')

    return true
  }

  startTypeWriter() {
    this.converter = new showdown.Converter({ tables: true, tablesHeaderId: true })
    this.typeWriter()
    return
  }

  typeWriter() {
    return new Promise(resolve => {
      if (this.indexWriter == 0) { this.response = '' }
      if (this.indexWriter < this.textToWrite.length) {
        //LOCAL CHAT
        this.response += this.textToWrite.charAt(this.indexWriter);
        //CHAT
        this.hist[this.hist.length - 1] = this.converter.makeHtml(this.response);
        this.indexWriter++;
        const randomSpeed = this.getRandomSpeed(this.minSpeed, this.maxSpeed);
        setTimeout(() => this.typeWriter(), randomSpeed);
      } else {
        this.indexWriter = 0
        resolve(true)
      }
    })
  }

  getRandomSpeed(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  async loadHistChats() {
    // console.log("🚀 ~ loadHistChats")
    let objAgg = {
      pipe: [
        {
          "$match": {
            // ativo: true,
            "username": this.user.username
          }
        },
        {
          "$group": {
            "_id": "$chatId",
            "chatId": {
              "$first": "$chatId"
            },
            "username": {
              "$first": "$username"
            },
            "subject": {
              "$first": {
                "$arrayElemAt": ["$parts", 0]
              }
            },
            // "parts": {
            //   "$push": { "parts": "$parts", "role": "$role" }
            // }
          }
        }
      ],
      options: {}
    }

    let resHistChats = await this.tools.aggregateAsync("cadAiHistory", objAgg)
    this.histChats = resHistChats
  }

  setStep(stepSelected: any) {
    this.step = stepSelected
  }

  setSugestion(element: any, sugestion: string) {
    this.request = sugestion
    this.click(element, 'send')
  }

  async setHistChat(element: any, histChat: any) {
    this.converter = new showdown.Converter({ tables: true, tablesHeaderId: true })

    let objAgg = {
      pipe: [
        {
          "$match": {
            "chatId": histChat.chatId
          }
        }
      ],
      options: {}
    }
    console.log("🚀 ~ this.user.username:", this.user.username)

    let resHistSelected: any = await this.tools.aggregateAsync("cadAiHistory", objAgg)
    console.log("🚀 ~ resHistSelected:", resHistSelected)

    this.hist = resHistSelected.map(e => {
      //REQUEST
      if (e.role == 'user') {
        return this.converter.makeHtml(e.parts[0].text)
      }
      //RESPONSE
      if (e.role == 'model') {
        //EXIBIR BOTÃO MOSTRAR LISTA PARA ATUALIZAR COMPONENTES DA TELA
        this.listIsTrue = true
        this.listResponse = e

        return this.converter.makeHtml(e.pretty.markdown)
      }
    })
    // console.log("🚀 ~ this.hist:", this.hist)

    this.histMapWhoIs = resHistSelected.map(e => {
      let obj = { chatIA: e.role == 'model', list: false, resultRequest: { msg: '' } }
      //REQUEST
      if (e.role == 'user') {
        obj.resultRequest.msg = this.converter.makeHtml(e.parts[0].text)
      }
      //RESPONSE
      if (e.role == 'model') {
        //EXIBIR BOTÃO MOSTRAR LISTA PARA ATUALIZAR COMPONENTES DA TELA
        this.listIsTrue = true
        this.listResponse = e
        obj.resultRequest = e
        obj.list = true
      }
      return obj
    })
    console.log("🚀 ~ this.histMapWhoIs:", this.histMapWhoIs)
  }

  startNewChat(element: any) {
    // console.log("🚀 ~ startNewChat")
    let document = element.view.document
    //INSTANCIA COMPONENTS LOCALCHAT
    this.timeForSession = new Date().getTime()
    this.hist = []
    this.histMapWhoIs = []
    this.requested = ''
    this.response = 'Olá, eu sou a inteligência artificial Applay, como posso te ajudar hoje?'

    setTimeout(() => { this.createLocalChat(document) }, 200)
  }
}