import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

declare var window: any;

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  key: string = "BEWAREOBLIVIONISATHAND"; //length=23
  keyportal: string = "YOUSHALLNOTPASS";

  utils: any = {
    genIV: () => {
      return Math.random().toString(36).substr(2, 30);
    },
    encrypt: (text, iv) => {
      let base = CryptoJS.AES.encrypt(text, this.key, { iv: iv });
      return base.toString();
    },
    decrypt: (text, iv) => {
      let data = CryptoJS.AES.decrypt(text, this.key, { iv: iv }).toString(CryptoJS.enc.Utf8);
      return data;
    },
    protect: (data) => {
      let iv = this.utils.genIV();
      return { cipher: this.utils.encrypt(JSON.stringify(data), iv), iv: iv, protect: true, app_key: '4vxaC8b019x6O3J9YJ3Q3qrr', app_secret: 'GKea2bC1UR8w' }
    },
    extract: (data) => {
      return JSON.parse(this.utils.decrypt(data.cipher, data.iv));
    }
  }

  sessionObj: any = {};
  clients: any = []

  env: string = 'prod';
  client: string = 'multi';
  baseUrl: string = '';

  constructor(private http: HttpClient) {}

  async setClient(client) {
    if (!client || this.clients.length == 0) {
      console.log('Erro, não tem clients');
      console.log('Client: ', client);
      console.log('Clients: ', this.clients);
      return false;
    }

    this.client = client;

    let clientObj = await this.getClientFull();
    if (!clientObj) {
      console.log('Clientes: ', this.clients);
      console.log(client, ' não encontrado');
      localStorage.clear();
      return false;
    }

    this.baseUrl = clientObj.url.api;

    // Titulo da aplicação
    if (clientObj.produto) window.document.title = clientObj.produto;

    // Cores dinamicas
    document.documentElement.style.setProperty('--primary', clientObj.tema?.primary || '#09242c');
    document.documentElement.style.setProperty('--secondary', clientObj.tema?.secondary || '#00c795');

    // Icone dinamico
    let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    if (favIcon) favIcon.href = clientObj.tema?.favicon || 'favicon.ico';

    return clientObj;
  }

  getClientFull() {
    return this.clients.find(e => e.value == this.client);
  }

  getClient() {
    return this.client;
  }

  async getClients() {
    if (!this.clients || this.clients.length == 0) await this.loadClients();
    return this.clients;
  }

  async loadClients() {
    let result = await this.http.get('https://admin.applayos.com/ambientes/V3').toPromise();
    console.log("🚀 ~ ApiService ~ loadClients ~ result:", result)
    
    if (!result) return alert('Erro !\nProdutos não encontrados');
    
    this.clients = result;
    this.clients.map(e => e.value = e.produto);

    const remember = JSON.parse(localStorage.getItem('_LOGIN'));
    const checkClient = this.clients.find(e=>e.produto == remember?.clientSelected)
    if (checkClient) checkClient.default = true;

    const url = window.location.hostname.split('.')[0];
    
    const checkClientes = this.clients.filter(e => e?.url?.dns.includes(url));
    if (checkClientes.length != 0 && checkClientes.length != this.clients.length) return checkClientes;

    return this.clients;
  }

  getEnv() {
    return this.env;
  }

  getUrl(type?) {
    if (type == 'io') { return this.baseUrl + 'ioportal'; }
    if (type == 'auth') { return this.baseUrl + 'auth'; }
    if (type == 'internal') { return this.baseUrl + 'api2/'; }
    return this.baseUrl + 'apiportal/';
  }

  setSession(sessionObj) {
    this.sessionObj = {
      username: sessionObj.username,
      userId: sessionObj.userId,
      session: sessionObj.session
    };
  }

  async post(url, data, callback, header?) {
    return this.http.post(url, data, { headers: header || {} }).subscribe((result: any) => {
      if (result.data && result.data.protect) {
        result.data = this.utils.extract(result.data);
      }
      callback(result);
    }, (err) => {
    });
  }

  portal(endpoint, data, callback) {
    let url = this.getUrl() + 'portal/main/' + endpoint;
    let dataBase = Object.assign(this.sessionObj, { data: data })
    let dataFinal = this.utils.protect(dataBase);
    return this.http.post(url, dataFinal, { headers: { "env": this.env, "client": this.client, "keyportal": this.keyportal } }).subscribe((result: any) => {
      if (result.data && result.data.protect) {
        result.data = this.utils.extract(result.data);
      }
      callback(result);
    }, (err) => {
      console.log('err', err);
    });
  }

  eauth(endpoint, data, callback) {
    let url = this.getUrl() + 'portal/auth/' + endpoint;

    let dataFinal = this.utils.protect(data);

    return this.http.post(url, dataFinal, { headers: { "env": this.env, "client": this.client, "keyportal": this.keyportal } }).subscribe((result: any) => {
      if (result.data && result.data.protect) {
        result.data = this.utils.extract(result.data);
      }
      callback(result);
    }, (err) => {
      console.log(err);
    });
  }
}
