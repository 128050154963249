import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModsService } from 'src/app/services/mods.service';
import { SyncService } from '../../services/sync.service';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { ToolsService } from '../../services/tools.service';
import { LangService } from '../../services/lang.service';

declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  subscription: Subscription;
  lista: any = [];
  showNotify: any = false;
  showLang: any = false;
  showUser: any = false;
  clienteObj: any = false;
  notifications: any = [];
  statusColor: any = 'success';
  statusText: any = 'Conectado';
  statusIcon: any = 'wifi';
  hideStatus: any = false;
  languages: any = [];
  language: any = {
    lang: 'pt',
    title: 'pt-BR',
    msg: {},
    status: false
  };

  userInfo: any = {};
  showIconLang: Boolean = false;

  constructor(
    private user: UserService,
    private sync: SyncService,
    private renderer: Renderer2,
    private tools: ToolsService,
    private api: ApiService,
    private lang: LangService,
    private mods: ModsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.getNotifications();

    this.userInfo = this.tools.getUserInfo();
    // console.log("🚀 -> this.userInfo:", this.userInfo);

    this.lang.getLanguages(result => {
      this.languages = result;
      this.language = this.lang.getLang();
      this.mods.updComponents({ type: 'translate', language: this.language });
    })

    this.subscription = this.sync.listenSync.subscribe(data => {
      if (data.type == 'status') {
        this.statusColor = data.statusColor;
        // console.log("🚀 -> data.statusColor:", data.statusColor);

        if (data.statusColor == 'success') {
          this.statusIcon = 'wifi';
          this.statusText = 'Conectado';

          setTimeout(() => {
            if (this.statusColor == 'success') this.hideStatus = true;
          }, 2500);

        }
        else if (data.statusColor == 'warning') {
          this.statusIcon = 'wifi-exclamation';
          this.statusText = 'Reconectando';
          this.hideStatus = false;
        }
        else if (data.statusColor == 'danger') {
          this.statusIcon = 'wifi-slash';
          this.statusText = 'Desconectado';
          this.hideStatus = false;
        }
      }

      if (data.type == 'notifications') {

        if (data.data) data.data.new = true;

        this.user.insertNotifications(data.data, (total) => this.notifications = total);
        // console.log(this.notifications);
      }
    });

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.showNotify) this.showNotify = !($(e.target).parents('.header-notifications').length == 0);
      if (this.showLang) this.showLang = !($(e.target).parents('.header-lang').length == 0);
      if (this.showUser) this.showUser = !($(e.target).parents('.header-user').length == 0);
    });

    // Logo dinamico
    this.clienteObj = await this.api.getClientFull();
    // console.log("🚀 -> this.clienteObj:", this.clienteObj);


    this.showIconLang = window.location.hostname.toLowerCase().includes('sophia') ||
                        this.clienteObj.produto.toLowerCase().includes('sophia')  ||
                        window.location.hostname.toLowerCase().includes('blstech') ||
                        this.clienteObj.produto.toLowerCase().includes('blstech')
  }

  timeDescription(dataTimestamp) {
    // Obter a data e hora atual
    const agora = new Date().getTime();
    // Converter o timestamp para milissegundos
    const data = dataTimestamp;

    // Calcular a diferença em milissegundos entre as duas datas
    const diferencaMS = agora - data;

    // Calcular a diferença em segundos
    const diferencaSegundos = Math.floor(diferencaMS / 1000);

    // Verificar se a data é "agora" (dentro de 1 minuto)
    if (diferencaSegundos < 60) {
      return "agora";
    }

    // Calcular a diferença em minutos
    const diferencaMinutos = Math.floor(diferencaSegundos / 60);

    // Verificar se a data é "minutos atrás"
    if (diferencaMinutos < 60) {
      return `${diferencaMinutos} minuto${diferencaMinutos > 1 ? "s" : ""} atrás`;
    }

    // Calcular a diferença em horas
    const diferencaHoras = Math.floor(diferencaMinutos / 60);

    // Verificar se a data é menos de 24 horas atrás
    if (diferencaHoras < 24) {
      return `${diferencaHoras} hora${diferencaHoras > 1 ? "s" : ""} atrás`;
    }

    // Calcular a diferença em dias
    const diferencaDias = Math.floor(diferencaHoras / 24);

    // Verificar se a data é menos de 7 dias atrás
    if (diferencaDias < 7) {
      return `${diferencaDias} dia${diferencaDias > 1 ? "s" : ""} atrás`;
    }

    // Se nenhuma das condições acima for atendida, retorna a data formatada
    return new Date(data).toLocaleDateString();
  }

  clickLang(item) {
    this.mods.updAlert({ type: 'loading', value: true });

    this.language = item;
    this.lang.setLang(this.language.lang);

    this.mods.updComponents({ type: 'scope' });
    this.mods.updComponents({ type: 'load' });
    this.mods.updComponents({ type: 'translate', language: this.language });

    this.sync.updSync({ type: 'menu' });

    setTimeout(() => this.mods.updAlert({ type: 'loading', value: false }), 250);
  }

  iniciais(valor) {
    return valor.split(" ").map((n) => n[0]).join("").toUpperCase();
  }

  getNotReaded() {
    return this.notifications.filter(e => !e.readed).length
  }

  getNotifications() {
    this.user.loadNotifications(data => {
      this.notifications = data.sort((a, b) => (a.time < b.time) ? 1 : ((a.time > b.time) ? -1 : 0));
    });
  }

  readAllNotify() {
    this.notifications.forEach(e => e.readed = true);
    this.notifications.forEach(e => delete e.new);

    this.user.setNotifications(this.notifications);
  }

  toggleNot() {
    this.showNotify = !this.showNotify;
    if (this.showNotify) this.notifications.forEach(e => e.dataText = this.timeDescription(e.time));
  }

  clickNotify(data) {
    data.readed = true;

    this.notifications.forEach(e => {
      if (e._id == data._id) { e.readed = true; delete e.new; }
    });

    this.user.setNotifications(this.notifications);

    if (data.shortcut) {
      let item = data.pathmodule;

      this.mods.getMod(item, null);
    }
  }

  minhaConta() {
    if (!!this.user.moduloUsuario) {

      this.tools.shortcut({
        mod: 'Usuários V3',
        modId: this.user.moduloUsuario,
        funcname: 'boot',
        params: { user: this.tools.getUserInfo() },
        pageType: 'layer',
      });
    }
  }

  sair() {
    this.user.logout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToHome() {
    let { menu } = this.user.getModsMenu();
    this.mods.getMod(menu[0].list[0].click, menu[0].name)
  }

}
