import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, TemplateRef, Renderer2, ElementRef } from '@angular/core';
import { ToolsService } from '../../services/tools.service';
import { LangService } from '../../services/lang.service';

declare var $;

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})

export class TableComponent implements OnInit {

  @ViewChild('switchTmpl', { static: true }) switchTmpl: TemplateRef<any>;
  @ViewChild('checkTmpl', { static: true }) checkTmpl: TemplateRef<any>;
  @ViewChild('checkHeaderTmpl', { static: true }) checkHeaderTmpl: TemplateRef<any>;
  @ViewChild('editTmpl', { static: true }) editTmpl: TemplateRef<any>;
  @ViewChild('removeTmpl', { static: true }) removeTmpl: TemplateRef<any>;
  @ViewChild('optionsTmpl', { static: true }) optionsTmpl: TemplateRef<any>;
  @ViewChild('progressTmpl', { static: true }) progressTmpl: TemplateRef<any>;
  @ViewChild('funcTmpl', { static: true }) funcTmpl: TemplateRef<any>;
  @ViewChild('selectTmpl', { static: true }) selectTmpl: TemplateRef<any>;
  @ViewChild('inputTmpl', { static: true }) inputTmpl: TemplateRef<any>;
  @ViewChild('filtro') filtro: ElementRef;
  @ViewChild('defaultTmpl', { static: true }) defaultTmpl: TemplateRef<any>;

  @Input() public comp: any;

  colMap: any = {}
  headerMap: any = {}
  listenerFn;

  datatable: any = {
    viewRows: [],
    rows: [],
    cols: []
  };

  clicked;
  all: boolean = false;

  repeatRateTimer;
  timeout;

  filterView: boolean = false;
  filterCol: any = '';
  totalElements: any = 0;
  filterField: any = '';
  filterValues: any = [];
  filterValuesFilter: any = [];
  filterSearchValue: any = '';
  filterAll: boolean = false;

  selectedFilters: any = [];
  searchValue: any = '';

  abrirModal: boolean = false
  selAll: boolean = false

  mask: any = {};
  bindCols: any = [];
  language: any = { msg: { buscarPor: '', removerItem: 'Remover?' } };
  showOptionTable: any = false;

  colors: Object = {
    'primary': '#136dfa',
    'secondary': '#6c757d',
    'success': '#3c8854',
    'danger': '#dd4045',
    'warning': '#fec236',
    'warning2': 'orange',
    'info': '#3ccaf0',
    'light': '#f8f9fa',
    'dark': '#212529',
  }

  constructor(
    private tools: ToolsService,
    private lang: LangService,
    private ref: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    this.listenerFn = this.renderer.listen('window', 'click', (e: Event) => {
      if (this.filtro && e.target !== this.filtro.nativeElement) {
        this.filterView = false;
      }
    });
    this.update();
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { }, 100);
  }

  processRows(rows) {
    if (this.bindCols.length == 0) { return rows; }
    let scope = this.tools.getScope(this.comp.pageType, this.comp.scope || 'item');
    for (var row of rows) {
      for (var bind of this.bindCols) {
        row[bind + '#bind'] = this.tools.nameParse(row[bind] || '', row[bind] || '', scope);
      }
    }
    return rows;
  }

  multiCols(header) {
    var result = [];
    this.bindCols = [];

    for (var item of header) {
      if (!item.dataType && item.fieldBind) {
        this.bindCols.push(item.fieldBind);
      }

      if (item.dataType == 'select' && item.fieldBind) {
        item.field = item.fieldBind;
        delete item.fieldBind;
      }

      if (item.dataType && item.dataType == 'multi') {
        let tableToCols = this.tools.getLoadOrig(this.comp.pageType, item.params.table);

        for (var col of tableToCols) {
          result.push({ name: col[item.params.nameId], field: col[item.params.fieldId], pageType: this.comp.pageType, show: true });
        }

        continue;
      }

      result.push(item);
    }

    return result
  }

  ngOnInit(): void {
    this.language = this.lang.getLang();
    this.mask = {
      'numero': '0*',
      'float': 'separator.2',
      'telefone': '(00) 0000-0000||(00) 0 0000-0000',
      'hora': '00:00',
      'data': '00/00/0000',
      'cpf': '000.000.000-00',
      'cnpj': '00.000.000/0000-00',
      'email': 'A*@A*.SSS.SS',
      'rg': '00.000.000-0',
      'cep': '00000-000',
      // 'password': '•*'
    }
    if (!this.comp.filter && this.comp.pageType == 'layer') { this.comp.filter = true; }
    if (this.comp.search === undefined && this.comp.pageType == 'layer') { this.comp.search = true; }
    if (!this.comp.nav && this.comp.pageType == 'layer') { this.comp.nav = true; }

    this.colMap = {
      'options': this.optionsTmpl,
      'checkbox': this.checkTmpl,
      'edit': this.editTmpl,
      'func': this.funcTmpl,
      'select': this.selectTmpl,
      'input': this.inputTmpl,
      'remove': this.removeTmpl,
      'switch': this.switchTmpl,
      'progress': this.progressTmpl,
      'default': this.defaultTmpl,
    }

    this.headerMap = { 'checkbox': this.checkHeaderTmpl }

    this.datatable.cols = this.multiCols(this.comp.header).filter(e => !e.hide).map(e => ({
      name: e.name,
      prop: e.fieldBind ? (e.fieldBind + '#bind') : e.field,
      mask: e.mask,
      prefix: e.prefix,
      canAutoResize: true,
      headerClass: ["edit", "remove", "func", "checkbox"].includes(e.dataType) ? 'header-cell-center' : 'header-cell',
      block: e.block,
      headerTemplate: this.headerMap[e.dataType],
      cellTemplate: this.colMap[e.dataType || 'default'],
      blockField: e.blockField,
      icon: e.icon,
      color: e.color,
      textColor: e.statusText,
      bgColor: e.status,
      param: e.param,
      options: e.optionsObj || e.options,
      maxWidth: 750,
      dataType: e.dataType,
      width: e.size || 150
    }));

    this.datatable.filters = this.datatable.cols.filter(e => !e.cellTemplate);
    this.datatable.rows = this.tools.getLoadOrig(this.comp.pageType, this.comp.table);
    this.datatable.viewRows = this.processRows([...this.datatable.rows]);
    this.datatable.footer = this.comp.footer === false ? false : '50';

    if (this.comp.bold && this.datatable.viewRows.length > 0) this.datatable.viewRows[this.datatable.viewRows.length - 1].bold = true;

    this.comp.updateTable = (col?) => this.updateTable(col);
    this.selectClear();
    this.ref.detectChanges();
    this.checkAll();

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.showOptionTable) this.showOptionTable = !($(e.target).parents('.table-header').length == 0);
    });
  }

  update() {
    window.dispatchEvent(new Event('resize'));
    this.checkAll();
  }

  checkAll() {
    if (this.datatable.viewRows.filter(e => e.select).length > 0) {
      this.all = this.datatable.viewRows.length == this.datatable.viewRows.filter(e => e.select).length;
    }
  }

  toggle(value, field, block) {
    if (block) { return }
    this.all = value;
    this.datatable.viewRows.forEach(e => e[field] = value);
  }

  toggleRow(row, value, field, block) {
    if (block) { return }
    row[field] = value;
  }

  changeFilterView(force?) {
    if (typeof force == 'boolean') { this.filterView = force; }
    else { this.filterView = !this.filterView; }
    if (!this.filterView) { this.filterCol = ''; this.filterField = ''; this.filterSearchValue = ''; this.filterAll = false; }
  }

  selectFilter(col) {
    this.filterCol = col.name; this.filterField = col.prop; this.filterSearchValue = '';
    this.filterValues = this.datatable.rows.map(e => ({ text: e[col.prop], selected: false })).sort((a, b) => a.text - b.text).filter((item, pos, ary) => !pos || item.text != ary[pos - 1].text);
    this.filterValuesFilter = [...this.filterValues];
  }

  selectAll(force?) {
    if (typeof force == 'boolean') { this.filterAll = force; }
    this.filterValuesFilter.forEach(e => e.selected = this.filterAll);
  }

  selectClear() {
    this.filterValues.forEach(e => e.selected = false);
    this.filterValuesFilter = [...this.filterValues];
  }

  filterSearch(value) {
    if (value != '') { this.filterValuesFilter = [...this.filterValues].filter(e => e.text.toUpperCase().split(value.toUpperCase()).length > 1); }
    else { this.filterValuesFilter = [...this.filterValues]; }
    this.filterAll = false;
  }

  filterApply() {
    let idx = this.selectedFilters.findIndex(e => e.name == this.filterCol);
    if (idx >= 0) { this.selectedFilters.splice(idx, 1); }
    this.selectedFilters.push({ name: this.filterCol, field: this.filterField, values: this.filterValuesFilter.filter(e => e.selected).map(e => e.text) });
    this.changeFilterView(false);
    this.search();
  }

  filterCancel() {
    this.changeFilterView(false);
  }

  removeFilter(filter) {
    let idx = this.selectedFilters.findIndex(e => e.name == filter.name);
    this.selectedFilters.splice(idx, 1);
    this.search();
  }

  filtersClear() {
    this.selectedFilters = [];
    this.search();
  }

  searchDelay() {
    clearTimeout(this.repeatRateTimer);
    this.repeatRateTimer = setTimeout(() => {
      this.search()
    }, 600);
  }

  ngAfterViewInit() {
  }

  updateTable(col?) {
    if (col) {
      this.datatable.cols = this.multiCols(this.comp.header).filter(e => e.show).map(e => ({
        name: e.name,
        prop: e.fieldBind ? (e.fieldBind + '#bind') : e.field,
        mask: e.mask,
        canAutoResize: true,
        headerClass: ["edit", "remove", "func", "checkbox"].includes(e.dataType) ? 'header-cell-center' : 'header-cell',
        block: e.block,
        headerTemplate: this.headerMap[e.dataType],
        cellTemplate: this.colMap[e.dataType || 'default'],
        blockField: e.blockField,
        icon: e.icon,
        color: e.color,
        textColor: e.statusText,
        bgColor: e.status,
        param: e.param,
        options: e.optionsObj || e.options,
        maxWidth: 750,
        dataType: e.dataType,
        width: e.size || 150
      }));
      this.language = this.lang.getLang();

    } else {
      this.datatable.viewRows = this.processRows(this.tools.getLoad(this.comp.pageType, this.comp.table));
    }
    if (this.comp.bold && this.datatable.viewRows.length > 0) this.datatable.viewRows[this.datatable.viewRows.length - 1].bold = true;
    this.update();
  }

  updataCols() {
    this.datatable.cols = this.multiCols(this.comp.header).filter(e => e.show).map(e => ({
      name: e.name,
      prop: e.fieldBind ? (e.fieldBind + '#bind') : e.field,
      mask: e.mask,
      canAutoResize: true,
      headerClass: ["edit", "remove", "func", "checkbox"].includes(e.dataType) ? 'header-cell-center' : 'header-cell',
      block: e.block,
      headerTemplate: this.headerMap[e.dataType],
      cellTemplate: this.colMap[e.dataType || 'default'],
      blockField: e.blockField,
      icon: e.icon,
      color: e.color,
      textColor: e.statusText,
      bgColor: e.status,
      param: e.param,
      options: e.optionsObj || e.options,
      maxWidth: 750,
      dataType: e.dataType,
      width: e.size || 150
    }));

    this.selAll = this.comp.header.filter(e => !["edit", "remove"].includes(e.dataType)).some(e => e.show);
  }

  search() {
    this.datatable.viewRows = this.processRows(this.tools.search(this.comp.pageType, this.comp.table, this.searchValue, this.selectedFilters));
  }

  select(row, column) {
    if (this.bindCols.includes(column.prop)) {
      let scope = this.tools.getScope(this.comp.pageType, this.comp.scope || 'item');
      row[column.prop + '#bind'] = this.tools.nameParse(row[column.prop] || '', row[column.prop] || '', scope);
      // row[column.prop + '#bind'] = row[column.prop];
    }
    this.cmd('func', row, column)
  }

  removeBind(row) {
    for (var bind of this.bindCols) {
      delete row[bind + '#bind'];
    }
    return row;
  }

  cmd(type, row, param?, ignore?) {
    if (type == 'edit') {
      // console.log('this.comp', param);

      // let editOptions = this.comp.header.find(e => e.dataType == 'edit');
      var optItem = {
        itemId: row._id,
        base: param.base
      };
      if (!param.base) {
        optItem = this.removeBind(Object.assign({}, row));
      }
      this.tools.layout({
        pageId: param.param,
        optItem: optItem
      });
    }
    if (type == 'remove') {
      // this.tools.alert({ alertType: 'confirm', msg: 'Deseja remover ?\nEsta ação é irreversível' });
      // return

      var x = confirm(this.language.msg.removerItem);
      if (!x) return;

      let removeOptions = this.comp.header.find(e => e.dataType == 'remove');
      this.tools.cmdOptions('deleteItem', { pageType: this.comp.pageType, base: removeOptions.base, item: row, pageId: removeOptions.param });
    }
    if (type == 'func') {
      let funcOptions = this.comp.header.find(e => e.dataType == 'func');
      let funcOptionsInputSelect = this.comp.header.find(e => e.field == param.prop);

      this.tools.cmdFunc(this.comp.pageType, (funcOptionsInputSelect.change || param || funcOptions.param), { item: row });
    }
    if (type == 'focus') {
      let funcOptions = this.comp.header.find(e => e.dataType == 'func');
      let funcOptionsInputSelect = this.comp.header.find(e => e.field == param.prop);
      this.tools.cmdFunc(this.comp.pageType, (funcOptionsInputSelect.focus || param || funcOptions.param), { item: row });
    }
    if (type == 'click' && !ignore) {
      let funcOptions = this.comp.header.find(e => e.dataType == 'checkbox');
      this.tools.cmdFunc(this.comp.pageType, (param || funcOptions.click), { item: row });
    }
  }

  ngOnDestroy() {
    this.listenerFn()
  }

  headerOption(tipo) {
    const rows = this.tools.dataForm(this.datatable.viewRows);
    const cols = this.datatable.cols.filter(e => !['edit', 'remover', 'func'].includes(e.dataType));
    const filename = this.tools.mods.mods.find(e => e.id == this.tools.mods.modActive)?.mod?.title || '';

    if (tipo == 'excel') this.tools.excel(rows, cols, filename, () => { });
    if (tipo == 'pdf') this.tools.pdf(rows, cols, filename, () => { });
    if (tipo == 'refresh') this.updataCols();
    if (tipo == 'config') {
      this.abrirModal = true
      this.selAll = this.comp.header.every(e => e.show)
    };

    this.showOptionTable = false;
  }

  fecharModal() {
    this.abrirModal = false
  }

  esconderColuna(idx: any) {
    let header = this.comp.header[idx]
    header.show = !header.show
    this.updataCols()
  }

  selecionarTodos() {
    let todosTrue = []
    for (let col of this.comp.header) {
      if (col.show && !["edit", "remove"].includes(col.dataType)) {
        todosTrue.push(col)
      }
    }
    if (todosTrue.length > 0) {
      todosTrue.map(e => e.show = false)
    } else {
      this.comp.header.map(e => e.show = true)
    }
    this.updataCols()

  }
}
