<div class="table-header" *ngIf="comp.filter||comp.search||comp.nav" [ngClass]="{'with-search':comp.search}">
  <div class="table-header-main">
    <div class="table-header-search" *ngIf="comp.search">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="var(--secondary)">
        <path id="icon_buscar-2" data-name="icon/buscar-2" d="M19,20a.994.994,0,0,1-.707-.292L12.906,14.32A7.923,7.923,0,0,1,8,16a8,8,0,1,1,8-8,7.922,7.922,0,0,1-1.68,4.906l5.387,5.386A1,1,0,0,1,19,20ZM8,2a6,6,0,1,0,6,6A6.007,6.007,0,0,0,8,2Z" />
      </svg>
      <input type="text" [attr.placeholder]="language.msg.buscarPor" name="" [(ngModel)]="searchValue" (keyup)="searchDelay()">
    </div>
    <i class="fa-light fa-ellipsis-stroke-vertical btnOptTbl" (click)="showOptionTable = !showOptionTable"></i>
  </div>

  <div class="table-header-options" *ngIf="showOptionTable">
    <div class="menu-pointer"></div>
    <div class="table-header-options-header">
      <div>Opções</div>
      <!-- <i class="fa-regular fa-xmark" style="cursor: pointer; width: 25px; height: 25px; display: flex; align-items: center; justify-content: center;" (click)="showOptionTable = false"></i> -->
    </div>
    <div class="table-header-options-list">
      <div class="table-header-options-list-item" (click)="headerOption('pdf')">
        <i class="fa-light fa-file-pdf" style="font-size: larger; min-width: 20px;"></i>
        <span>Exportar em PDF</span>
      </div>
      <div class="table-header-options-list-item" (click)="headerOption('excel')" style="border-bottom: 1px solid #D8D8D8;">
        <i class="fa-light fa-file-excel" style="font-size: larger; min-width: 20px;"></i>
        <span>Exportar em Excel</span>
      </div>
      <div class="table-header-options-list-item" (click)="headerOption('refresh')">
        <i class="fa-light fa-rotate-right" style="font-size: larger; min-width: 20px"></i>
        <span>Atualizar Tabela</span>
      </div>

      <div class="table-header-options-list-item" (click)="headerOption('config')">
        <i class="fa-light fa-gear" style="font-size: larger; min-width: 20px"></i>
        <span>Configurar Tabela</span>
      </div>
    </div>
  </div>
</div>

<div [ngClass]="'prefix-css '+(comp.dataType=='table'?'prefix-table-css':'prefix-card-css')">
  <ngx-datatable #table [scrollbarV]="true" [scrollbarH]="true" columnMode="force" [headerHeight]="46" [rowHeight]="46" [rows]="datatable.viewRows" [columns]="datatable.cols" [footerHeight]="datatable.footer" [count]="totalElements" [ngStyle]="{'height':(comp.height||(comp.pageType=='layer'?'calc(100vh - 310px)':'400px'))}">

    <!-- Options (Funcionalidade OK, arrumar layout) -->
    <ng-template #optionsTmpl let-rowIndex="rowIndex" let-row="row" let-column="column">
      <div class="cel-item">
        <div class="dropdown" [ngClass]="{'clicked':clicked==rowIndex}">
          <span (click)="clicked=rowIndex"><i class="fa fa-ellipsis-h"></i></span>
          <div class="dropdown-content">
            <div class="dropdown-item" *ngFor="let opt of column.options" (click)="cmd(opt.dataType,row, opt.param)">
              <span *ngIf="opt.dataType=='func'"><i [ngClass]="'fa fa-'+(opt.icon)"></i></span>
              <span *ngIf="opt.dataType=='edit'"><i class="fa fa-edit"></i></span>
              <span *ngIf="opt.dataType=='remove'"><i class="fa fa-times"></i></span>
              <span>{{opt.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Progresso (Arrumar layout, não tem funcionalidade) -->
    <ng-template #progressTmpl let-row="row" let-value="value">
      <div class="cel-item">
        <div class="progress-bar" [style.--progress]="50"></div>
        <span>{{50}}%</span>
      </div>
    </ng-template>

    <!-- Switch (Layout OK, não tem funcionalidade, falta um selectAll) -->
    <ng-template #switchTmpl let-row="row" let-value="value">
      <div class="cel-item">
        <label class="toggle">
          <input class="toggle-checkbox" type="checkbox">
          <div class="toggle-switch"></div>
        </label>
      </div>
    </ng-template>

    <!-- Padrão (Texto) -->
    <ng-template #defaultTmpl let-row="row" let-value="value" let-column="column">
      <div class="cel-item" [ngStyle]="column.bgColor && colors[row[column.bgColor]] ? {background: colors[row[column.bgColor]], color: 'white'} : column.textColor ? {color: colors[row[column.textColor]]} : {}">
        <span *ngIf="!column.mask" [ngStyle]="{'font-weight':row.bold ? 'bold':'unset'}">{{value}}</span>
        
        <!-- Padrão (Texto) com mascaras -->
        <input *ngIf="column.mask=='moeda'" currencyMask  [ngStyle]="{'border':'none', 'background-color':'white', 'font-family': 'Poppins, sans-serif', 'font-size':'13px', 'color': '#09242c'}" [(ngModel)]="row[column.prop]" [disabled] = true [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }">
        <input *ngIf="column.mask=='virgula'" currencyMask  [ngStyle]="{'border':'none', 'background-color':'white', 'font-family': 'Poppins, sans-serif', 'font-size':'13px', 'color': '#09242c'}" [(ngModel)]="row[column.prop]" [disabled] = true [options]="{ prefix: '', thousands: '.', decimal: ',', align: 'left' }">        
      </div>
    </ng-template>

    <!-- Botão Edit -->
    <ng-template #editTmpl let-row="row" let-value="value" let-column="column">
      <div class="cel-item align-center btn" (click)="cmd('edit',row,column)">
        <div class="btn-edit"><i [ngClass]="[column.icon?'fa fa-'+(column.icon):'fa fa-edit']"></i></div>
      </div>
    </ng-template>

    <!-- Botão Remover -->
    <ng-template #removeTmpl let-row="row" let-value="value">
      <div class="cel-item align-center btn" (click)="cmd('remove',row)">
        <div class="btn-edit"><i class="fa fa-trash" style="color:red"></i></div>
      </div>
    </ng-template>

    <!-- Botão cmdFunc -->
    <ng-template #funcTmpl let-row="row" let-value="value" let-column="column">
      <div class="cel-item align-center btn" (click)="cmd('func',row, column.param)">
        <div class="btn-edit">
          <i [ngClass]="'fa fa-'+(column.icon)" [style.color]="column.color ? colors[column.color] : ''"></i>
        </div>
      </div>
    </ng-template>

    <!-- Input Edit -->
    <ng-template #inputTmpl let-rowIndex="rowIndex" let-row="row" let-column="column" let-value="value">
      <div class="cel-item" *ngIf="column.block||row[column.blockField]" [ngStyle]="column.bgColor && colors[row[column.bgColor]] ? {background: colors[row[column.bgColor]], color: 'white'} : column.textColor ? {color: colors[row[column.textColor]]} : {}">
        <span [ngStyle]="{'font-weight':row.bold ? 'bold':'unset'}">{{value}}</span>
      </div>
      <div class="inputDiv" *ngIf="!column.block&&!row[column.blockField]">
        <input *ngIf="column.mask=='moeda'" currencyMask class="input" [ngClass]="{'input-disabled':column.block||row[column.blockField]}" type="text" [(ngModel)]="row[column.prop]" [attr.type]="column.mask=='password'?'password':'text'" (blur)="cmd('func', row, column)" (focus)="cmd('focus', row, column)" [disabled]="column.block||row[column.blockField]" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }">
        <input *ngIf="column.mask=='float'" class="input" [ngClass]="{'input-disabled':column.block||row[column.blockField]}" type="text" [(ngModel)]="row[column.prop]" [attr.type]="column.mask=='password'?'password':'text'" (blur)="cmd('func', row, column)" (focus)="cmd('focus', row, column)" [disabled]="column.block||row[column.blockField]" [mask]="mask[column.mask]" thousandSeparator="." decimalMarker=",">
        <input *ngIf="column.mask!='moeda'&&column.mask!='float'" class="input" [ngClass]="{'input-disabled':column.block||row[column.blockField]}" type="text" [(ngModel)]="row[column.prop]" [attr.type]="column.mask=='password'?'password':'text'" (blur)="cmd('func', row, column)" (focus)="cmd('focus', row, column)" [disabled]="column.block||row[column.blockField]" [dropSpecialCharacters]="false" [mask]="mask[column.mask]">
      </div>

    </ng-template>

    <!-- Select -->
    <ng-template #selectTmpl let-rowIndex="rowIndex" let-row="row" let-column="column">
      <div class="selectDiv">
        <select class="select" [(ngModel)]="row[column.prop]" (change)="select(row,column)">
          <option *ngFor="let sel of column.options" [value]="sel.value||sel">{{sel.view||sel}}</option>
        </select>
      </div>
    </ng-template>

    <!-- Checkbox Header (selectAll) -->
    <ng-template #checkHeaderTmpl let-column="column">
      <div class="checkHeader" (click)="toggle(!all,column.prop,column.block)" style="display: flex; justify-content: center;width: 100%; padding-right: 5px;">
        <input style="height: 20px;" type="checkbox" [(ngModel)]="all" [attr.disabled]="column.block" *ngIf="!comp.unselectAll" id="selectAll">
        <label class="name" *ngIf="column.showLabel">{{column.name}}</label>
      </div>
    </ng-template>

    <!-- Checkbox -->
    <ng-template #checkTmpl let-row="row" let-column="column">
      <div class="cel-item align-center" (click)="toggleRow(row,!row[column.prop],column.prop,column.block)" style="display: flex; justify-content: center;">
        <input style="height: 20px;" type="checkbox" [(ngModel)]="row[column.prop]" [attr.disabled]="column.block||row[column.blockField]" (click)="cmd('click', row, column.param,row[column.blockField])" id="teste2" (change)="checkAll()">
      </div>
    </ng-template>

  </ngx-datatable>
</div>

<div *ngIf="abrirModal" class="modal">
  <div (click)="fecharModal()" class="overflow"></div>
  <div class="modal-overflow">
    <div class="modal-box">
      <div class="header-modal">
        <div class="modal-title">
          Configurações da Tabela
        </div>
        <div (click)="fecharModal()" class="modal-close">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="select-all-modal">
        <div class="cel-item">
          <label class="toggle">
            <input (click)="selecionarTodos()" class="toggle-checkbox" type="checkbox" [checked]="selAll">
            <div class="toggle-switch"></div>
          </label>
        </div>
        <div class="">Selecionar todos</div>
      </div>
      <div class="modal-table">
        <table class="table-border">
          <tr>
            <th>Coluna</th>
            <th>Exibir</th>
          </tr>
          <ng-container *ngFor="let col of comp.header; index as i">
            <tr *ngIf='!["edit", "remove"].includes(col.dataType)'>
              <td>{{col.name}}</td>
              <td>
                <label class="toggle">
                  <input (click)="esconderColuna(i)" class="toggle-checkbox" type="checkbox" [checked]="col.show">
                  <div class="toggle-switch"></div>
                </label>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
      <div class="total-cols">Total: {{comp.header.length}}</div>
    </div>
  </div>
</div>