<div class="workspace-overflow" [ngStyle]="{'padding': checkCompIA? '12px 48px': '48px'}">
  <div class="workspace-breadcrumb" *ngIf="!checkCompIA">
    <div class="workspace-breadcrumb-item">{{language.msg.caminho}}: </div>
    <div class="workspace-breadcrumb-item"><i class="fa fa-angle-right"></i></div>
    <div class="workspace-breadcrumb-item">{{mod.title}}</div>
  </div>

  <div class="workspace-title-menu" *ngIf="!checkCompIA">
    <div class="workspace-title-div" *ngIf="!checkCompIA"
      [ngClass]="{'workspace-title-right':layer.params.titleRight,'workspace-title-center':layer.params.titleCenter}">
      {{layer.params.title}}</div>
    <div class="workspace-menu" *ngIf="layer.params.menu && layer.params.menu.length > 0">
      <div *ngFor="let menu of layer.params.menu" [ngClass]="'workspace-menu-main-item'"
        (click)="cmd(menu.menuType,menu,'layer')">
        <div class="workspace-menu-main-icon">
          <i *ngIf="menu&&menu.icon" [ngClass]="[menu.icon?'fa fa-'+(menu.icon):'fa fa-null']"></i>
        </div>
        <div class="workspace-menu-main-text">{{menu?menu.name:''}}</div>
      </div>
    </div>
  </div>

  <div class="workspace-layer">
    <div *ngFor="let comp of layer.components" class="workspace-component"
      [ngStyle]="{'width':''+(100/12*(comp.size||12))+'%', 'display':comp.show?'block':'none'}">
      <div *ngIf="comp.dataType=='title'" class="component-item"><app-title [comp]="comp"></app-title></div>
      <div *ngIf="comp.dataType=='form'" class="component-item"><app-form [comp]="comp"></app-form></div>
      <div *ngIf="comp.dataType=='ia'" class="component-item"><app-ia [comp]="comp"></app-ia></div>
      <div *ngIf="comp.dataType=='chatia'" class="component-item"><app-chatia [comp]="comp"></app-chatia></div>
      <div *ngIf="comp.dataType=='table'" class="component-item"><app-table [comp]="comp"></app-table></div>
      <div *ngIf="comp.dataType=='dash'" class="component-item"><app-dash [comp]="comp"></app-dash></div>
      <div *ngIf="comp.dataType=='dashia'" class="component-item"><app-dashia [comp]="comp"></app-dashia></div>
      <div *ngIf="comp.dataType=='nav'" class="component-item"><app-nav [comp]="comp"></app-nav></div>
      <div *ngIf="comp.dataType=='text'" class="component-item"><app-text [comp]="comp"></app-text></div>
      <div *ngIf="comp.dataType=='progress'" class="component-item"><app-progress [comp]="comp"></app-progress></div>
      <div *ngIf="comp.dataType=='step'" class="component-item"><app-step [comp]="comp"></app-step></div>
      <div *ngIf="comp.dataType=='collapse'" class="component-item"><app-collapse [comp]="comp"></app-collapse></div>
      <div *ngIf="comp.dataType=='modules'" class="component-item"><app-modules [comp]="comp"></app-modules></div>
      <div *ngIf="comp.dataType=='map'">
        <div [attr.id]="comp.id" [ngStyle]="{'width':'100%','height':comp.height||'100%'}"></div>
      </div>
      <div
        *ngIf="['text','title','form', 'chatia', 'ia','table','dash', 'dashia','nav','progress','step','collapse','modules','map'].indexOf(comp.dataType)<0"
        class="component-item">dataType inválido layer - {{comp.dataType}} - {{comp.name}}</div>
    </div>
    <div style="clear: both;"></div>
  </div>
  <div class="workspace-modal" *ngIf="modalId!=''">
    <div (click)="exitModal()" class="overflow"></div>
    <div class="workspace-modal-overflow">
      <div class="workspace-modal-box">
        <div class="workspace-modal-title"
          [ngClass]="{'workspace-modal-title-right':modal.params.titleRight, 'workspace-modal-title-center':modal.params.titleCenter}">
          {{ modal.params.titleNew && modal.params.itemNew ? modal.params.titleNew : modal.params.title }}
        </div>
        <div (click)="exitModal()" class="workspace-modal-close">
          <i class="fa fa-times"></i>
        </div>
        <div class="workspace-modal-content">
          <div *ngFor="let comp of modal.components" class="workspace-component"
            [ngStyle]="{'width':''+(100/12*(comp.size||12))+'%','display':comp.show?'block':'none','padding':comp.dataType=='form'?0:null}">
            <div *ngIf="comp.dataType=='title'" class="component-item"><app-title [comp]="comp"></app-title></div>
            <div *ngIf="comp.dataType=='form'" class="component-item"><app-form [comp]="comp"></app-form></div>
            <div *ngIf="comp.dataType=='table'" class="component-item"><app-table [comp]="comp"></app-table></div>
            <div *ngIf="comp.dataType=='dash'" class="component-item"><app-dash [comp]="comp"></app-dash></div>
            <div *ngIf="comp.dataType=='nav'" class="component-item"><app-nav [comp]="comp"></app-nav></div>
            <div *ngIf="comp.dataType=='text'" class="component-item"><app-text [comp]="comp"></app-text></div>
            <div *ngIf="comp.dataType=='progress'" class="component-item"><app-progress [comp]="comp"></app-progress>
            </div>
            <div *ngIf="comp.dataType=='step'" class="component-item"><app-step [comp]="comp"></app-step></div>
            <div *ngIf="comp.dataType=='collapse'" class="component-item"><app-collapse [comp]="comp"></app-collapse>
            </div>
            <div *ngIf="comp.dataType=='modules'" class="component-item"><app-modules [comp]="comp"></app-modules></div>
            <div *ngIf="comp.dataType=='map'">
              <div [attr.id]="comp.id" [ngStyle]="{'width':'100%','height':comp.height||'100%'}"></div>
            </div>
            <div
              *ngIf="['text','title','form','table','dash','nav','progress','step','collapse','modules','map'].indexOf(comp.dataType)<0"
              class="component-item">dataType inválido modal - {{comp.dataType}} - {{comp.name}}</div>
          </div>
          <div style="clear: both;"></div>
        </div>
        <div class="workspace-modal-menu"
          [ngClass]="{'workspace-modal-menu-left':modal.params.menuLeft, 'workspace-modal-menu-right':modal.params.menuRight}">
          <div *ngFor="let itemMenu of modal.params.menu" (click)="cmd(itemMenu.menuType,itemMenu,'modal')"
            [ngStyle]="{'display':itemMenu.show?'block':'none'}"
            [ngClass]="'workspace-modal-menu-item button-'+(itemMenu.color)+' '+(itemMenu.show?'workspace-modal-menu-button':'')">
            <i *ngIf="itemMenu && itemMenu.icon" [ngClass]="[itemMenu.icon?'fa fa-lg fa-'+(itemMenu.icon):'']"></i>
            {{itemMenu.name}}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>