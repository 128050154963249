import { Component, OnInit, Input } from '@angular/core';
import { ModsService } from '../../services/mods.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})

export class AlertComponent implements OnInit {

  @Input() public alert:any;

  constructor( private mods:ModsService ) { }

  ngOnInit(): void {
  }

  cancel(reason){
    this.mods.updAlert({status:false,click:true})
  }

  confirm(reason){
    this.mods.updAlert({status:false,click:true,alertType:'confirm',confirm: reason})
  }

}
